/* LandingPage.css */
@import url("https://fonts.googleapis.com/css2?family=Geist:wght@100..900&display=swap");
@import url("https://unpkg.com/normalize.css") layer(normalize);

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Your custom layers */
@layer normalize, custom-base, demo, stick, effect, scrollbar, debug;

@layer normalize, base, demo, stick, effect, scrollbar, debug;

@layer debug {
  [data-debug="true"] .landing-page-list li {
    outline: 0.05em dashed currentColor;
  }
  [data-debug="true"]
    :is(.landing-page-subtitle, .landing-page-list li:last-of-type) {
    outline: 0.05em dashed canvasText;
  }
}

@layer scrollbar {
  @property --hue {
    initial-value: 0;
    syntax: "<number>";
    inherits: false;
  }
  @property --chroma {
    initial-value: 0;
    syntax: "<number>";
    inherits: true;
  }

  [data-sync-scrollbar="true"] {
    scrollbar-color: oklch(var(--lightness) var(--chroma) var(--hue)) #0000;
  }
  @supports (animation-timeline: scroll()) and (animation-range: 0% 100%) {
    [data-sync-scrollbar="true"][data-animate="true"] {
      timeline-scope: --list;
      scrollbar-color: oklch(var(--lightness) var(--chroma, 0) var(--hue))
        #000000ea;
      animation-name: change, chroma-on, chroma-off;
      animation-fill-mode: both;
      animation-timing-function: linear;
      animation-range:
        entry 50% exit 50%,
        entry 40% entry 50%,
        exit 30% exit 40%;
      animation-timeline: --list;
      .landing-page-list {
        view-timeline: --list;
      }
    }
  }

  @keyframes change {
    to {
      --hue: var(--end);
    }
  }
  @keyframes chroma-on {
    to {
      --chroma: 0.3;
    }
  }
  @keyframes chroma-off {
    to {
      --chroma: 0;
    }
  }
}

@layer effect {
  :root {
    --start: 0;
    --end: 360;
    --lightness: 65%;
    --base-chroma: 0.3;
  }
  [data-theme="dark"] {
    --lightness: 75%;
  }
  [data-theme="light"] {
    --lightness: 65%;
  }

  .landing-page-list {
    --step: calc((var(--end) - var(--start)) / (var(--count) - 1));
  }
  .landing-page-list li:not(:last-of-type) {
    color: oklch(
      var(--lightness) var(--base-chroma)
        calc(var(--start) + (var(--step) * var(--i)))
    );
  }

  @supports (animation-timeline: scroll()) and (animation-range: 0% 100%) {
    [data-animate="true"] {
      .landing-page-list li {
        opacity: 0.2;
        animation-name: brighten;

        &:first-of-type {
          --start-opacity: 1;
        }
        &:last-of-type {
          --brightness: 1;
          --end-opacity: 1;
        }
        animation-fill-mode: both;
        animation-timing-function: linear;
        animation-range: cover calc(50% - 1lh) calc(50% + 1lh);
        animation-timeline: view();
      }
    }

    @keyframes brighten {
      0% {
        opacity: var(--start-opacity, 0.2);
      }
      50% {
        opacity: 1;
        filter: brightness(var(--brightness, 1.2));
      }
      100% {
        opacity: var(--end-opacity, 0.2);
      }
    }
  }
}

@layer stick {
  .landing-page-content {
    --font-level: 6;
    display: flex;
    line-height: 1.25;
    width: 100%;
    max-width: 1400px;
    padding-left: 5rem !important;
    margin: 0 auto !important; /* Center horizontally */
  }
  .landing-page-final {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .landing-page-end {
      --font-level: 6;
      color: black;
    }
  }
  .landing-page-main {
    width: 100%;
  }
  .landing-page-subtitle {
    position: sticky;
    top: calc(50% - 0.5lh);
    font-size: inherit;
    margin: 0;
    display: inline-block;
    height: fit-content;
    font-weight: 600;
  }
  .landing-page-list {
    font-weight: 600;
    padding-inline: 0;
    margin: 0;
    list-style-type: none;
  }

  [data-snap="true"] {
    scroll-snap-type: y proximity;

    .landing-page-list li {
      scroll-snap-align: center;
    }
  }

  .landing-page-subtitle,
  .landing-page-list li:last-of-type {
    background: linear-gradient(
      canvasText 50%,
      color-mix(in oklch, canvas, canvasText 25%)
    );
    background-clip: text;
    color: #000000cb;
  }
}

@layer demo {
  .landing-page-header {
    min-height: 90vh;
    display: flex;
    padding-top: 20vh !important;
    width: 100%;
    padding-inline: 5rem;
    justify-content: center;
  }

  .landing-page-title {
    --font-size-min: 24;
    --font-level: 8;
    text-wrap: pretty;
    line-height: 0.8;
    margin: 0;
    background: linear-gradient(
      canvasText 60%,
      color-mix(in oklch, canvas, canvasText)
    );
    background-clip: text;
    color: #000000c4;
  }
}

@layer base {
  :root {
    --font-size-min: 14;
    --font-size-max: 20;
    --font-ratio-min: 1.1;
    --font-ratio-max: 1.33;
    --font-width-min: 375;
    --font-width-max: 1500;
  }

  .landing-page :where(.fluid) {
    --fluid-min: calc(
      var(--font-size-min) * pow(var(--font-ratio-min), var(--font-level, 0))
    );
    --fluid-max: calc(
      var(--font-size-max) * pow(var(--font-ratio-max), var(--font-level, 0))
    );
    --fluid-preferred: calc(
      (var(--fluid-max) - var(--fluid-min)) /
        (var(--font-width-max) - var(--font-width-min))
    );
    --fluid-type: clamp(
      (var(--fluid-min) / 16) * 1rem,
      ((var(--fluid-min) / 16) * 1rem) -
        (((var(--fluid-preferred) * var(--font-width-min)) / 16) * 1rem) +
        (var(--fluid-preferred) * var(--variable-unit, 100vi)),
      (var(--fluid-max) / 16) * 1rem
    );
    font-size: var(--fluid-type);
  }

  .landing-page *,
  .landing-page *:after,
  .landing-page *:before {
    box-sizing: border-box;
  }

  .landing-page {
    display: grid;
    place-items: center;
    background: light-dark(rgb(255, 255, 255), rgb(255, 255, 255));
    min-height: 100vh;
    color: rgb(0, 0, 0); /* Change this to the desired color */
    font-family: "Geist", "SF Pro Text", "SF Pro Icons", "AOS Icons",
      "Helvetica Neue", Helvetica, Arial, sans-serif, system-ui;
  }

  .landing-page-bear-link {
    color: canvasText;
    position: fixed;
    top: 1rem;
    left: 1rem;
    width: 48px;
    aspect-ratio: 1;
    display: grid;
    place-items: center;
    opacity: 0.8;
  }

  /* Utilities */
  .landing-page .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .landing-page div.tp-dfwv {
    position: fixed;
  }
}

.landing-page-redirect-button {
  background-color: black;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
}

.landing-page-arrow {
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(45deg);
  margin-right: 0.2rem;
}
